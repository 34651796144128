
export default class ItemsProvider {
    constructor(initialItems) {
        this.items = initialItems || [];
    }

    getItems() {
        return this.items || [];
    }

    setItems(items) {
        this.items = items;
    }

    isItemsEqual(item1, item2) {
        return (item1?.image && item1.image != item2?.image) || (item1?.svg && item1.svg != item2?.svg);
    }

    hasNext(item) {
        return this.isItemsEqual(this.items[this.items.length - 1], item);
    }

    hasPrevious(item) {
        return this.isItemsEqual(this.items[0], item);
    }

    next(index) {
        return this.items[index + 1];
    }

    previous(index) {
        return this.items[index - 1];
    }

    setOnMoreNeeded(onMoreNeeded) {
        this.onMoreNeeded = onMoreNeeded;
    }

    fireOnMoreNeeded() {
        return this.onMoreNeeded ? this.onMoreNeeded() : Promise.resolve();
    }
}